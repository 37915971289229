import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, Table } from "reactstrap";
import configBack from "../../config-back.json";
import authHeader from "../../auth-header";
import "./Order.css";
import { orderStatutList as statutLst } from "../../helpers/orderStatutList";
function OrderTab() {
  // Variable d'état //
  const [order, setOrder] = useState([]);
  // Lors du premier affichage //
  useEffect(getOrders, []);
  // Récupèration des transactions //
  async function getOrders() {
    const options = {
      method: "GET",
      headers: authHeader(),
    };

    let url = configBack.url_back + "/user/cmd"; // c'est clair !! :)
    const response = await fetch(url, options);
    //console.log("Salut Order" + JSON.stringify(response));
    const orderData = await response.json();
    //console.log("Coucou Order" + JSON.stringify(orderData));

    setOrder(orderData.orders); // Il y aura surement un point quelque chose
  }

  function renderStatut(order){
    if(order){     
      const st = statutLst.find((st) => st.value === order.statut);
      if (st) {
        return(<div className={`rounded-pill d-inline-block p-1`} style={{backgroundColor : st.color, color : st.textcolor}}>{st.txt}</div>)
        //return st.txt;
      }
      else return order.statut;     
    }
  }

  function renderAdress(order){
    if(order){
      let retVal="";
      if(order.address) retVal +=order.address;
      if(order.address2){
        if(retVal.length>0 ) retVal+="\n ";
        retVal+=order.address2;
      }
      if(order.cp || order.ville){
        if(retVal.length > 0 ) retVal+="\n ";
        if(order.cp) retVal+=order.cp;
        retVal+=order.ville ? ' '+ order.ville : '';      
      }
      return retVal;
    }
  }

  function renderOrder() {
    if (order) {
      const longueur = order.length;
      let tab = [];
      //console.log("Prout Order = " + order.length);
     // console.log("MyTab Order = " + JSON.stringify(order));
      for (let i = 0; i < longueur; i++) {
        let row = (
          <tr>
            <td>{new Date(order[i].date).toLocaleDateString()}</td>
            <td>{order[i].offre.titre}</td>
            <td style={{whiteSpace:'pre-line'}}>
              {renderAdress(order[i])}
              {/* {order[i].address}
              {order[i].address2}
              {order[i].cp}
              {order[i].ville} */}
              {order[i].email}
            </td>

            <td>{renderStatut(order[i])}</td>
          </tr>
        );
        tab.push(row);
      }
      return tab;
    }
  }

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader>
            <h3>Historique des commandes</h3>
            <span></span>
          </CardHeader>
          <div className="card-block row">
            <Col sm="12" lg="12" xl="12">
              <div className="table-responsive">
                <Table>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Date de commande</th>
                      <th scope="col">Récompenses</th>
                      <th scope="col">Adresse d'envoi</th>
                      <th scope="col">Statut de la commande</th>
                    </tr>
                  </thead>
                  <tbody>{renderOrder()}</tbody>
                </Table>
              </div>
            </Col>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default OrderTab;
