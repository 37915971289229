export default function headerWithToken (){
  if (localStorage.getItem("readyReward-token")) {
    const userToken = localStorage.getItem("readyReward-token");
    if (userToken) {
      return { Authorization: "Bearer " + userToken ,'Content-Type': 'application/json'};  
    }
  }
   return {};
};
     
