import React, { Fragment } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import CreateOffer from "../../component/createOffer/createOffer";
import OrderList from "../../component/orderList/orderList";
//Dashboard Proncipal//
const PageAdmin = (props) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Col sm="12">
          <OrderList />
          <CreateOffer />
        </Col>
      </Container>
    </Fragment>
  );
};

export default PageAdmin;
