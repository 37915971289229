import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, Table } from "reactstrap";
import "./actionTab.css";
import configBack from "../../config-back.json";
import authHeader from "../../auth-header";
function ActionTab() {
  // Variable d'état //
  const [transaction, setTransaction] = useState([]);
  // Lors du premier affichage //
  useEffect(getTransacations, []);
  // Récupèration des transactions //
  async function getTransacations() {
    const options = {
      method: "GET",
      headers: authHeader(),
    };

    let url = configBack.url_back + "/user/transactions";
    const response = await fetch(url, options);
    const tansactionData = await response.json();

    setTransaction(tansactionData.transactions);
  }

  function renderActions() {
    if (transaction) {
      const longueur = transaction.length;
      let tab = [];
      for (let i = 0; i < longueur; i++) {
        let row = (
          <tr>
            <td>{transaction[i].action}</td>
            <td>
              {transaction[i].amount} {transaction[i].currency}
            </td>
            <td>
              {new Date(transaction[i].createdAt).toLocaleDateString() +
                " " +
                new Date(transaction[i].createdAt).toLocaleTimeString()}
            </td>
          </tr>
        );
        tab.push(row);
      }
      return tab;
    }
  }

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader>
            <h3>Historique des actions</h3>
            <span>
              Liste de vos dernières actions efectuée avec votre application
              ReadyPark
            </span>
          </CardHeader>
          <div className="card-block row">
            <Col sm="12" lg="12" xl="12">
              <div className="table-responsive">
                <Table>
                  <thead className="lights">
                    <tr>
                      <th scope="col">Vos actions</th>
                      <th scope="col">Gains générés</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>{renderActions()}</tbody>
                </Table>
              </div>
            </Col>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
export default ActionTab;
