import React from "react";
export const MENUITEMS = [
  {
    title: "Accueil",
    icon: <i className="pe-7s-home pe-lg"></i>,
    path: "/accueil",
    type: "sub",
    active: true,
    bookmark: true,
  },  
];
