//Interface admin...
import React, { Fragment,useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";

import { Container, Card, CardBody,Table,Input ,Label,FormGroup, Form} from "reactstrap";

import configBack from "../../config-back.json";
import authHeader from "../../auth-header";
import "./orderList.css";
import { orderStatutList as statutLst } from "../../helpers/orderStatutList";



const OrderList = (props) => {

  const [orders, setOrders] = useState([]);  

useEffect(getOrders, []);
async function getOrders() {
  const options = {
    method: "GET",
    headers: authHeader(),
  };

  let url = configBack.url_back + "/admin/cmd"; 
  const response = await fetch(url, options);    
  const responseData = await response.json();   
  setOrders(responseData.orders); //
} 
function renderUser(order){
  let usrName="";
  let usrMail="";
  if(order?.usernameOriginal ) usrName=(<small className="d-block">{order?.usernameOriginal}</small>);
  if(order?.emailUserOriginal ) usrMail=(<small className="d-block">{order?.emailUserOriginal}</small>);
  if(order?.usernameOriginal || order?.emailUserOriginal){
    return (<div>{usrName}{usrMail} </div>);
  }
}

function renderAdress(order){
  if(order){
    let retVal="";
    if(order.address) retVal +=order.address;
    if(order.address2){
      if(retVal.length>0 ) retVal+="\n ";
      retVal+=order.address2;
    }
    if(order.cp || order.ville){
      if(retVal.length > 0 ) retVal+="\n ";
      if(order.cp) retVal+=order.cp;
      retVal+=order.ville ? ' '+ order.ville : '';      
    }
    return retVal;
  }
}

function renderOrders() {
  if (orders) {
    return orders.map((order) => (
      <tr>
        <td>
          <div>{new Date(order.date).toLocaleDateString()}</div>
          {renderUser(order)}
        </td>
        <td>{order.offre.titre}</td>
        <td style={{whiteSpace:'pre-line'}}>
          {/* {order.address}
          {order.address2}
          {order.cp}
          {order.ville} */}
          {renderAdress(order)}
          {order.email}
        </td>        
        <td>          
          <Form><FormGroup className="d-lg-flex m-0 p-0"> {renderModifComponents(order)} </FormGroup></Form>
        </td>
      </tr>
    ));
  }
}

async function onUpdateCmdClicked(order, newStatut){
 if(order){
   order.statut = newStatut.value;     
   var options = {
     method: "POST",
     body: JSON.stringify(order),
     headers: authHeader(),
   };
   let url = configBack.url_back + "/admin/modifer-cmd"; 
   const response = await fetch(url, options);
   const responseData = await response.json();
   if( orders && responseData?.success){
     const elementIndex = orders.findIndex((el) => el._id === order._id);
     if (elementIndex != -1) {
       let newData = [...orders];
       newData[elementIndex]=order;          
       setOrders(newData);
     }
   }
 }  
}

function renderModifComponents(order) {
  const styleChecked={}
  return statutLst.map((st) => (        
    // <FormGroup check className={ st.value !== order.statut ? 'mx-1':'mx-1 border'} style={{backgroundColor:st.color, color:st.textcolor}} ></FormGroup>
      <FormGroup check className={`mx-1 my-md-1 my-sm-1 rounded`}  style={{backgroundColor : st.color, color : st.textcolor}} >
        <Label check className={`mr-1  p-2`}  style={ st.value !== order.statut ? { fontWeight: 'normal' } : { fontWeight: 'bold' } }>  
        {/* <Label check className='mr-1 p-1' style={ st.value !== order.statut ? { fontWeight: 'normal' } : { fontWeight: 'bold' } }>   */}       
          <Input
            type="radio"
            name="statut"
            defaultChecked ={st.value === order.statut}
            value={st.value}
            onChange={()=>onUpdateCmdClicked(order,st)}
            className='statutCmd'
          />
          {st.txt}
        </Label>
      </FormGroup>    
  ));
} 

  return (
    <Fragment>
      <Breadcrumb parent="Default" title="Commandes en cours" />
      <Card >
        <CardBody className="_px-0">
            <Table className="table-responsive">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date de commande</th>
                  <th scope="col">Récompenses</th>
                  <th scope="col">Adresse d'envoi / Mail</th>
                  <th scope="col">Statut de la commande</th>                    
                </tr>
              </thead>
              <tbody>{renderOrders()}</tbody>
            </Table>
          <div >
          </div>
        </CardBody>
      </Card>
      <Container fluid={true}>
      </Container>
    </Fragment>
  );
}

export default OrderList;
